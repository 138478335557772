@font-face {
  font-family: 'medallicons';
  src: url('./medallicons.eot');
  src: url('./medallicons.eot#iefix') format('embedded-opentype'),
       url('./medallicons.woff2') format('woff2'),
       url('./medallicons.woff') format('woff'),
       url('./medallicons.ttf') format('truetype'),
       url('./medallicons.svg#medallicons') format('svg');
  font-weight: normal;
  font-style: normal;
}

 .medallicons {
  font-family: "medallicons";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  height: 1em;
  // margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  // margin-left: .2em;

  /* fix firefox centering issue with top and bottom */
  // margin-top: .2em;
  // margin-bottom: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.iconArrowDropdown:before { content: '\e800'; } /* '' */
.iconArrowLeft:before { content: '\e801'; } /* '' */
.iconArrowRight:before { content: '\e802'; } /* '' */
.iconCancel:before { content: '\e803'; } /* '' */
.iconCheck:before { content: '\e804'; } /* '' */
.iconPoweredByMedallia:before { content: '\e805'; } /* '' */
.iconCalendar:before { content: '\e807'; } /* '' */
.iconInfo:before { content: '\e808'; } /* '' */
.iconStar:before { content: '\e809'}
.iconTrashBin:before { content: '\e810'}
.iconUploadArrow:before { content: '\e811'}
.iconAudioFile:before { content: '\e900'}
.iconExcelFile:before { content: '\e901'}
.iconGenericFile:before { content: '\e902'}
.iconImageFile:before { content: '\e903'}
.iconPDFFile:before { content: '\e904'}
.iconPowerPointFile:before { content: '\e905'}
.iconVideoFile:before { content: '\e906'}
.iconWordFile:before { content: '\e907'}
.iconZipFile:before { content: '\e908'}

 /*  give medallicon a name readable to voice reader but hidden from screen */
.medalliconLabel {
  opacity: 0;
  position: absolute;
  z-index: -1;
  left: 50%
}
