@import '../../css/variables';

.textfieldContainer {
  border: $border;
  margin: 0;
  padding: 0;
  box-shadow: $box-shadow-default;
}

.textField {
  display: block;
  padding: $sp-l $sp-xl;
  font-size: $fs-m;
  border: none;
  outline: none;
  transition: $box-shadow-transition;
  box-sizing: border-box;
  width: 100%;

  &:hover,
  &:focus {
    box-shadow: $box-shadow-focus;
  }

  :global(.hasTouch) &:focus,
  :global(.hasTouch) &:hover {
    box-shadow: $box-shadow-default;
  }
}

.textfieldCount {
  position: absolute;
  right: 0;
  font-size: $fs-s;
  text-align: right;
  padding-top: $half-spacing;
  padding-right: $half-spacing;
  opacity: 0.50;

  :global(.rtl) & {
    left: 0;
    right: inherit;
    padding-left: $half-spacing;
    padding-right: 0;
  }
}

.textfieldCharacterTyped {
  font-size: 0px;
}

@media #{$mobile-tablet} {
  .textField {
    padding: $sp-m 14px $sp-xxxl 14px;
  }

  .textfieldCount {
    padding-right: 0;
    padding-left: 0;
  }
}

.textfieldCharacterLimit {
  padding-left: $sp-xs;
  display: flex;
  align-items: center;


  :global(.rtl) & {
    flex-direction: row-reverse;
    padding-right: $sp-xs;
  }
}

.textfieldWarningBlock {
  padding-top: $half-spacing;
  display: flex;
  align-items: center;
}

.textfieldMessageContainer {
  display: flex;
  justify-content: space-between;
  font-size: $fs-s;
  height: $sp-xxxl;
}

.icon_lineHeight {
  line-height: inherit;
}

.textfieldContainer_isFocused {
  border: $border-width solid transparent;
  box-shadow: $box-shadow-focus;

  :global(.hasTouch) & {
    border: $border;
    box-shadow: none;
  }
}