@import '../../../css/variables';

$_height: 42px;

.dayContainer {
  position: relative;
  border: $border;
  width: 100%;
  height: 100%;
}

.day {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: $color-black-blue;
  display: block;
  width: 100%;
  text-decoration: none;
  vertical-align: middle;
  outline: none;

  transition: box-shadow $ease-in-out-transition;

  // for IE9 to make box-shadow work inside of border-collapse: collapse table
  border-collapse: separate;
  height: $_height;

  :global(.hasNoTouch) &:hover,
  :global(.hasNoTouch) &:focus {
    box-shadow: $box-shadow-focus;
  }
}

.dayLabel {
  min-height: 38px;
  line-height: 38px;
}

.day_isDisabled {
  opacity: 0.3;
  cursor: default;

  :global(.hasTouch) &:hover,
  :global(.hasTouch) &:focus {
    box-shadow: none;
  }

  * {
    cursor: default;
  }
}

.dayContainer_isEmpty {
  border: none;
}

@media #{$mobile-tablet} {
  .day {
    :global(.hasTouch) &:hover,
    :global(.hasTouch) &:focus {
      box-shadow: none;
    }
  }
}
