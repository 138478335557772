@import '../../css/variables';

.label {
  cursor: pointer;
  font-size: $fs-xss;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 8px;
  width: fit-content;
}

.label > input {
  outline: none;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  appearance: checkbox;
}

.label > input:disabled {
  cursor: not-allowed;
}
