@import '../../css/variables';

.textfield {
  box-sizing: border-box;
  display: block;
  border: $border;
  border-radius: 0;
  font-size: $fs-m;
  outline: none;
  transition: $box-shadow-transition, color $linear-transition;
  padding: $sp-l $sp-xl;
  width: 100%;

  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;

  &:focus, &:hover {
    box-shadow: $box-shadow-focus;
    border: 1px solid transparent;
  }

  :global(.hasTouch) &:focus, :global(.hasTouch) &:hover {
    box-shadow: none;
  }
}

@media #{$mobile-tablet} {
  .textfield {
    padding: $sp-m 14px;

    &:focus, &:hover {
      border: $border;
      box-shadow: none;
    }
  }
}

.textarea {
  max-width: 100%;
  min-width: 100%;
  min-height: 100px;
}
