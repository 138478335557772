@import '../../css/variables';
@import '../../css/animation';

.dropdownContainer {
  position: relative;
  background-color: $color-white;
  box-shadow: $box-shadow-default;
  cursor: pointer;
  min-width: 250px;
  text-align: left;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: $color-mobile-tap;

  :global(.rtl) & {
    text-align: right;
  }
}

.dropdownLabel {
  padding-bottom: $sp-l;
}

label.dropdownLabel {
  display: block;
}

.dropdownLabel_isInactive {
  display: none;
}

.dropdownSelector {
  box-sizing: border-box;
  position: relative;
  border: $border;
  opacity: 0;
  transition: $box-shadow-transition;
  width: 100%;

  &:hover,
  &:focus {
    box-shadow: $box-shadow-focus;
    outline: none;
  }

  :global(.hasTouch) &:hover,
  :global(.hasTouch) &:focus {
    box-shadow: none;
  }
}

button.dropdownSelector {
  background: transparent;
  text-align: left;
  padding: 0;
}

.dropdownSelector_isInactive {
  opacity: 1;
}

.dropdownSelector_isDisable {
  opacity: 0.5;

  &:hover,
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.dropdownTitle {
  display: inline-block;
  border-bottom: $border-width solid transparent;
  border-top: $border-width solid transparent;
  box-sizing: border-box;
  max-width: 310px;
  overflow: hidden;
  padding: $sp-l $sp-xl;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 90%;
  height: 55px;
}

.dropdownTitleSlideIn {
  @include slideInRight($duration: 0.3s, $delay: 0.13s);
}

.dropdownIcon {
  position: absolute;
  top: 0;
  right: $sp-xl;
  bottom: 0;
  margin: auto;
  font-size: 20px;
  vertical-align: middle;

  :global(.rtl) & {
    left: $sp-xl;
    right: initial;
  }
}

.dropdownIconSlideIn {
  @include slideInRight($duration: 0.3s);
}

.dropdownList {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: $color-white;
  border: $border-width solid $color-gray;
  box-shadow: $box-shadow-default;
  margin-bottom: $sp-xl;
  transform-origin: top;
  width: 100%;
  z-index: $z-index-dropdown;
}

// DO NOT USE visibility FOR ANIMATION
// because height isn't recalculated on Safari mobile so if the list
// extends beyond the survey height, it will not let the user scroll down to
// select an option
//
// To solve this problem, we use keyframes to toggle display and allow
// animation so that it will recalculate the height and allow the user to
// scroll pass the height of the survey if the list is long
.dropdownList_isActive {
  display: block;
  animation: dropdownOpeningAnimation 0.2s ease;
}

@keyframes dropdownOpeningAnimation {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
    transform: scale(1, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.dropdownListItem {
  position: relative;
  padding: $sp-l $sp-xxxl $sp-l $sp-xl;
  border-bottom: $border-width solid $color-gray;
  transition: $box-shadow-transition;
  transform: translateZ(0);
  word-wrap: break-word;

  &:first-child {
    border-top: $border-width solid transparent;
  }

  &:last-child {
    border-bottom: $border-width solid transparent;
  }

  &:hover,
  &:focus {
    box-shadow: $box-shadow-focus;
    outline: none;
  }

  :global(.hasTouch) &:hover {
    box-shadow: none;
  }
}

.dropdownListItemMultiValued {
  padding: $sp-l $sp-xl;
}

.dropdownListItemMultiValuedCheckboxCell {
  padding-right: $sp-m;
}

.dropdownListItemWithLinkFocused {
  box-shadow: $box-shadow-focus;
  outline: none;
}

.dropdownListItemLink {
  width: 100%;
  text-align: left;
  background: 0;
  border: 0;
  background: transparent;
  outline: none;
  box-sizing: border-box;
  color: currentColor;
}

@media #{$mobile-tablet} {
  :global(#languageSelector) {
    margin-top: $sp-xxl;
    display: block;
  }

  .dropdownContainer {
    width: 100%;
  }

  .dropdownSelector {
    -webkit-tap-highlight-color: $color-mobile-tap;

    &:hover,
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .dropdownTitle {
    max-width: 430px;
    padding-right: $sp-xl + $sp-m;

    :global(.rtl) & {
      padding-right: $sp-xl;
    }

    .dropdownTitleCaps {
      text-transform: uppercase;
    }
  }

  .dropdownList {
    min-width: 180px;
    top: auto;
    box-shadow: none;
  }

  .dropdownListItem {
    :global(.hasTouch) &:hover,
    :global(.hasTouch) &:focus {
      box-shadow: none;
    }
    -webkit-tap-highlight-color: $color-mobile-tap;
  }
}

@media #{$mobile-only} {
  .dropdownTitle {
    max-width: 310px;
  }
}
