@import "../../../../../css/variables.scss";

.container.full {
  width: 100%;
}

.button {
  border-radius: 18px;
  font-size: $fs-xss;
  height: 36px;
  padding: 0 1rem;
  font-weight: bold;
}

.button.outlined {
  color: $color-blue;
  background-color: $color-white;
  border: 1px solid $color-gray-light;

  &:hover,
  &:focus {
    background-color: #eaf3ff;
    border-color: #b9dfff;
  }
}

.button.text {
  color: $color-blue;
  background-color: $color-white;
  border: none;

  &:hover,
  &:focus {
    background-color: #eaf3ff;
    box-shadow: 0 0 0 1px #b9dfff;
    border-radius: 18px;
  }


}

@media #{$mobile-cookie-banner-with-wrapper} {
  .button {
    width: 100%;
  }
}