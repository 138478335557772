@import "../../../../css/variables.scss";

.categoryItem {
  align-items: center;
  background-color: #eaf3ff;
  border: none;
  border-radius: 8px;
  color: #2b3449;
  display: flex;
  flex-direction: row;
  font-size: $fs-xss;
  font-weight: bold;
  min-height: 48px;
  height: 48px;
  justify-content: space-between;
  margin-bottom: $sp-xxl;
  padding-left: $sp-xl;
  padding-right: $sp-xl;
  text-decoration: none;
}

@media #{$mobile-only} {
  .categoryItem {
    margin-bottom: $sp-l;
  }
}
