@import '../../css/variables';

.findOtherLocation {
  text-align: center;
  display: table;
  width: 100%;
  border: $border;
  box-sizing: border-box;
  box-shadow: $box-shadow-default;
  height: 56px;
  cursor: pointer;
  margin-top: $sp-xl;
}

.findOtherLocation_hasBoxShadow {
  :global(.hasNoTouch) & {
    box-shadow: $box-shadow-focus;
  }
}

.findOtherLocationInner {
  display: table-cell;
  vertical-align: middle;
  outline: none;
}

@media #{$mobile-only} {
  :global(.hasNoTouch) .findOtherLocation {
    margin-top: $sp-n;
  }
}
