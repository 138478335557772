@import '../../css/variables';

$_arrow-transition: 0.3s ease;
$_arrow-opacity: 0.55;
$_arrow-spacing: 8px;
$_arrow-width: 20px;

.button {
  box-sizing: border-box;
  border: none;
  border-radius: $sp-xs;
  font-size: $fs-m;
  outline: 0;
  padding: $sp-l $sp-xl;
  text-align: center;
  width: 100%;
  vertical-align: middle;
  transition: $background-color-transition;
  zoom: 1;

  :global(.hasNoTouch) &:hover .buttonArrowIcon,
  :global(.hasNoTouch) &:focus .buttonArrowIcon{
    opacity: $_arrow-opacity;
    padding-left: $_arrow-spacing;
    width: $_arrow-width;

    :global(.rtl) & {
      opacity: $_arrow-opacity;
      padding-left: 0;
      padding-right: $_arrow-spacing;
    }
  }
}

.button_isPrimary {
  background-color: $color-blue;
  color: $color-white;

  &:hover, &:focus {
    background-color: $color-blue-dark;
  }
}

.button_isSecondary {
  background-color: $color-gray;

  &:hover, &:focus {
    background-color: $color-gray-dark;
  }
}

.button_isTertiary {
  background-color: $color-black-blue;
  color: $color-gray;

  &:hover, &:focus {
    background-color: $color-black-blue-darker;
  }
}

.buttonCaption {
  vertical-align: middle;
}

.buttonArrowIcon {
  font-size: $_arrow-width;
  width: 0;
  opacity: 0;
  vertical-align: middle;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: opacity, padding-left, padding-right, width;

  :global(.rtl) & {
    transform: rotate(180deg);
  }

  :global(.hasTouch) & {
    opacity: $_arrow-opacity;
    padding-left: $_arrow-spacing;
  }

  :global(.hasTouch):global(.rtl) & {
    padding-left: 0;
    padding-right: $_arrow-spacing;
  }
}

@media #{$mobile-tablet} {
  .buttonArrowIcon {
    opacity: $_arrow-opacity;
    padding-left: $_arrow-spacing;
    width: $_arrow-width;

    :global(.hasNoTouch) &:hover .buttonArrowIcon,
    :global(.hasNoTouch) &:focus .buttonArrowIcon{
      opacity: $_arrow-opacity;
      padding-left: $_arrow-spacing;
      width: $_arrow-width;

      :global(.rtl) & {
        opacity: $_arrow-opacity;
        width: $_arrow-width;
        padding-left: 0;
        padding-right: $_arrow-spacing;
      }
    }
  }
}
