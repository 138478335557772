@import '../../../css/variables';

$_iconFontSize: $fs-ml;
$_iconPaddingRight: $sp-m;

.container {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;

  background-color: #FAFAFB;
  border-radius: 20px;
  color: $color-gray-meta;
  font-size: $fs-m;
  line-height: $fs-ml;
  padding-bottom: $sp-l - 1;
  padding-left: $sp-xxl;
  padding-right: $sp-xxl;
  padding-top: $sp-l - 1;

  & + li {
    margin-top: $sp-m;
  }

  transition: border $ease-in-out-transition;
}

.closeIcon {
  font-size: $_iconFontSize;
}

.documentIcon {
  font-size: $_iconFontSize;
  padding-right: $_iconPaddingRight;
}

.closeButton {
  display: inline-block;
  padding-left: $sp-l;
  padding-right: 0;
  background: none;
  border: none;
  text-align: center;
  text-decoration: none;
}

$_mobileIconFontSize: $fs-s;
$_mobileIconPaddingRight: 6px;
$_mobileDocumentIconHeight: 20px;

@media #{$mobile-only} {
  .container {
    border-radius: $sp-s;
    font-size: $fs-xss;
    line-height: $fs-m;
    padding-bottom: $sp-s;
    padding-left: $sp-m;
    padding-right: $sp-m;
    padding-top: $sp-s;
  }

  .documentIcon {
    font-size: $_mobileIconFontSize;
    height: $_mobileDocumentIconHeight;
    line-height: $_mobileDocumentIconHeight;
    padding-right: $_mobileIconPaddingRight;
  }

  .closeIcon {
    font-size: $_mobileIconFontSize;
  }

  .closeButton {
    padding-left: 6px;
  }
}
