@import 'variables';

.hasBorder {
  border: $border;
  transition: border $ease-in-out-transition;
}

:global(.hasNoTouch) {
  .hasBorder.hasHover:hover {
    border: $border-width solid transparent;
  }

  .hasHover {
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    box-shadow: $box-shadow-default;
    transition: transform $ease-in-out-transition, box-shadow $ease-in-out-transition;
  }

  .hasHover:hover {
    box-shadow: $box-shadow-focus;
    transform: translateZ(0);
    z-index: $z-index-survey-body + 1;
  }
}

@media #{$mobile-tablet} {
  .hasBorder.hasHover:hover {
    border: $border;
  }

  .hasHover:hover {
    box-shadow: $box-shadow-default;
  }
}
