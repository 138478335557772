@import '../../css/variables';

$_button-padding: 5px;
$_arrow-transition: 0.3s ease;
$_arrow-opacity: 0.55;
$_arrow-spacing: 8px;

.navigationButtonsContainer {
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  padding: $sp-xxxl $sp-n $sp-xxxxl $sp-n;
  width: 100%;
  display: table;
  table-layout: fixed;
  border-collapse: separate;
  direction: rtl;
  :global(.rtl) & {
    direction: ltr;
  }

  &.ltr {
    direction: ltr;
    :global(.rtl) & {
      direction: rtl;
    }
  }
}

.navigationButtonsContainer_hasOneButton {
  .pageButtonContainer {
    button { width: 50%; }
  }
}

.navigationButtonsContainer_hasTwoOrThreeButtons {
  .pageButtonContainer:first-child {
    padding-left: $_button-padding;

    &.ltr {
      padding-right: $_button-padding;
    }

    :global(.rtl) & {
      padding-left: 0;
      padding-right: $_button-padding;


      &.ltr {
        padding-left: $_button-padding;
        padding-right: 0;
      }
    }
  }

  .pageButtonContainer:last-child {
    padding-right: $_button-padding;

    &.ltr {
      padding-left: $_button-padding;
    }

    :global(.rtl) & {
      padding-left: $_button-padding;
      padding-right: 0;

      &.ltr {
        padding-left: 0;
        padding-right: $_button-padding;
      }
    }
  }
}

.pageButtonContainer {
  display: table-cell;
  direction: ltr;
  :global(.rtl) & {
    direction: rtl;
  }
}

@media #{$mobile-tablet} {
  .navigationButtonsContainer_hasOneButton {
    .pageButtonContainer {
      button { width: 100%; }
    }
  }

  .navigationButtonsContainer_hasTwoOrThreeButtons {
    .pageButtonContainer:first-child {
      width: 70%;
    }

    .pageButtonContainer:last-child {
      width: 30%;
    }
  }

  .pageButtonArrowIcon {
    opacity: $_arrow-opacity;
    margin-left: $_arrow-spacing;

    :global(.rtl) & {
      margin-left: 0;
      margin-right: $_arrow-spacing;
    }
  }
}
