@import '../../../css/variables';

.mobileNaOptionMainContainer {
  position: relative;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: $color-mobile-tap;
}

.mobileNaOptionContainer {
  position: relative;
  text-align: center;
  width: $option-min-height;
}

.indicator {
  vertical-align: middle;
  margin-top: -1px;
}

.input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-survey-body-input-layer;
}

.mobileNaOptionCaption {
  margin: 0;
  padding-right: $sp-m;
  padding-top: $sp-m;
  padding-bottom: $sp-m;
  font-size: $fs-xs;
  vertical-align: middle;

  :global(.rtl) & {
    padding-right: 0;
    padding-left: $sp-m;
  }
}
