@import '../../../css/variables';

.row {
  display: table-row;
  box-sizing: border-box;
  vertical-align: middle;
  width: 100%;

  &.isGridRowCellAlignmentEnabled {
    display: table;
    border-collapse: collapse;
    position: relative;
    word-break: break-all;
  }
}