@import '../../css/variables';

$_icon-width: 58px;
$_icon-width-mobile: 50px;

.calendarContainer {
  width: 100%;
}

.contentContainer {
  position: relative;
  width: 100%;
  vertical-align: middle;
}

.calenderTextfieldInput {
  position: relative;
  display: inline-block;
  transform: translateZ(0);
  vertical-align: middle;
  width: calc(50% - 58px);
  box-shadow: $box-shadow-default;

  &:focus, &:hover {
    z-index: 2;
  }
}

.calendarIconContainer {
  position: relative;
  display: inline-block;
  border: $border;
  border-left: 1px solid transparent;
  box-sizing: border-box;
  outline: none;
  line-height: normal;
  padding: $sp-l 0;
  text-align: center;
  text-decoration: none;
  transition-duration: 0.15s;
  transition-property: box-shadow, background, color, border;
  transition-timing-function: ease-in-out;
  vertical-align: middle;
  width: $_icon-width;
  box-shadow: $box-shadow-default;

  :global(.rtl) & {
    border-right: 1px solid transparent;
    border-left: $border;
  }

  &:focus, &:hover {
    box-shadow: $box-shadow-focus;
    border: 1px solid transparent;
    z-index: 2;
  }

  :global(.hasTouch) &:focus, :global(.hasTouch) &:hover {
    box-shadow: none;
  }
}

.calendarIcon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: $fs-xl;
}

.calendarIconContainer_isActive {
  background: $color-blue;
  border: $border-width solid $color-blue;
  color: $color-white;
}

.calendarIconContainer_inActive {
  color: $color-blue;
}

.calendarDatePickerContainer {
  display: none;
  position: absolute;
  top: $_icon-width - 1px;
  z-index: $z-index-calendar;
}

.calendarDatePickerContainer_isVisible {
  display: table;
}

@media #{$mobile-tablet} {
  .calendarIconContainer {
    padding: $sp-m;
    width: $_icon-width-mobile;

    &:focus, &:hover {
      border: $border;
      border-left: 1px solid transparent;
      box-shadow: none;
    }
  }

  .calendarIconContainer_isActive,
  .calendarIconContainer_isActive:hover,
  .calendarIconContainer_isActive:focus {
    border: $border-width solid $color-blue;
  }

  .calendarDatePickerContainer {
    top: $_icon-width-mobile - 1px;
  }

  .calenderTextfieldInput {
    width: calc(100% - 50px);
  }
}