@import '../../../css/variables';

.dropdownContainer {
  min-width: 100px;
  height: 40px;
}

.dropdownSelector {
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}

.modalHeaderStyles {
  padding: 0 $sp-xxxxxl 0 $sp-xl;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
