@import '../../../css/variables';

.optionContainer {
  backface-visibility: hidden;
  border-collapse: separate;
  box-sizing: border-box;
  cursor: pointer;
  min-height: $option-min-height;
  transition: box-shadow $ease-transition;
}

.optionContainer_hasBoxShadow {
  :global(.hasNoTouch) & {
    box-shadow: $box-shadow-focus;
  }
}

.input {
  position: absolute;
  top: 0;
  left: 7px;
  right: 0;
  bottom: 0;
  height: 60px;
  width: auto;
  margin: auto;
  cursor: pointer;
  opacity: 0;
  outline: none;
  appearance: none;
}

.indicatorContainer {
  position: relative;
  padding-left: $sp-xl;
  width: $option-min-height;

  :global(.rtl) & {
    padding-left: 0;
    padding-right: $sp-xl;
  }

  input[type=radio] {
    max-width: 49px;
  }

  input[type=checkbox] {
    max-width: 49px;
  }
}

.indicatorContainerHorizontal {
  position: relative;
  padding-left: $sp-xl;
  padding-right: $sp-xl;
  width: $option-min-height;

  :global(.rtl) & {
    padding-left: 0;
    padding-right: $sp-xl;
  }
}

.selectionUnit {
  box-sizing: border-box;
  border-top: $border;
  border-left: $border;
  border-bottom: $border;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: $color-mobile-tap;

  &:last-child {
    border-right: $border;
  }

  :global(.rtl) &:last-child {
    border-right: none;
  }

  :global(.rtl) &:first-child {
    border-right: $border;
  }
}

.indicator {
  margin-top: 2px;
}

.captionContainer {
  padding-top: $sp-l;
  padding-bottom: $sp-l;
  padding-right: $sp-xl;

  :global(.rtl) & {
    padding-right: 0;
    padding-left: $sp-xl;
  }
}

.textfieldContainer {
  padding-right: $sp-xl;
}

.otherOptionTextfield {
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  transition-delay: 0.25s;
  max-height: 200px;

  :global(.input_textarea) {
    max-height: 200px - 2*$sp-l;
  }
}

.otherOptionTextfield_isHidden {
  max-height: 0;
}

.indicator, .caption {
  vertical-align: middle;
}

.caption {
  word-break: break-word;
}

.bazaarVoiceOption {
  background-color: $color-yellow;
}

@media #{$mobile-tablet} {
  .optionContainer {
    -webkit-tap-highlight-color: $color-mobile-tap;
  }
}

@media #{$mobile-only} {
  :global(.hasTouch) .optionContainer_hasBoxShadow {
    box-shadow: none;
  }
  .input {
    width: 360px;
  }
}

.inSubComponent {
  font-size: 14px;
}
