@import '../../../css/variables';

.weekdayHeadersContainer {
  border-bottom: $border;
  font-size: $fs-s;
  height: 42px;
}

.weekdayLabel {
  opacity: 0.5;
  font-weight: inherit;
}
