/* Style for broken spinner in Safari
 * Remove after removing feature flags mechanism
 */

.loadingSpinner {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-left: 8px;
}

.svgPath {
  animation: rotate 1.5s infinite linear;
  transform-origin: 50px 50px;
  fill: currentcolor;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** End of old styles **/

.container {
  top: 5px;
  display: inline-block;
  position: relative;
  margin-left: 12px;
}

.loader {
  border: 1.5px solid transparent;
  border-top: 1.5px solid currentcolor;
  border-left: 1.5px solid currentcolor;
  border-radius: 50%;
  width: calc(1rem - 3px);
  height: calc(1rem - 3px);
  animation: spin 1.3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
