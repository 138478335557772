@import '../../css/variables';

.container {
  display: grid;
  grid-template-columns: 25% 75%;

}
.rowCaptions {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  grid-column: 1;
 }

 .rowContent {
   grid-column: 2;
 }

.leftCaptionContainer {
  padding-right: 10px;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  padding-bottom: $sp-xl;

  :global(.rtl) & {
    padding-right: 0;
    padding-left: 10px;
  }
}

.topCaptionContainer {
  margin-bottom: $sp-xxl;
}

.topCaption {
  font-size: $fs-m;
  margin: 0;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  line-height: $sp-xxl;
}

@media #{$mobile-tablet} {
  .topCaptionContainer {
    display: block;
    margin-bottom: 0;
    margin-top: $sp-xl;
  }

  .leftCaptionContainer {
    display: none;
  }

  .topCaption {
    font-weight: bold;
  }
}

