@import '../../../css/variables';

.cellOptionContainer,
.starScale {
  position: relative;
}

.indicatorContainer {
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  height: $option-min-height-rem;
  width: 100%;
  transition: box-shadow 0.15s ease-in-out;
  transform: translateZ(0);
}

.caption {
  min-height: $option-min-height-rem;
}

.indicator_isStar,
.indicator_isRadio,
.indicator_isCheckbox {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: $z-index-survey-body-input-layer;

  :global(.hasNoTouch) &:hover + .indicatorContainer,
  :global(.hasNoTouch) &:focus + .indicatorContainer {
    box-shadow: $box-shadow-focus;
  }

  :global(.hasNoTouch) &:hover + .indicatorContainer_isNaOption,
  :global(.hasNoTouch) &:focus + .indicatorContainer_isNaOption {
    border: $border-width solid transparent;
    box-shadow: $box-shadow-focus;
  }
}

@media #{$mobile-tablet} {
  .caption {
    font-size: $fs-s;
    margin-bottom: $sp-xl;
  }

  .input {
    :global(.hasTouch) &:hover + .indicatorContainer,
    :global(.hasTouch) &:focus + .indicatorContainer {
      box-shadow: none;
    }
  }
}
