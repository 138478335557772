@import '../../css/variables';

$_circle-z-index: 5;
$_label-z-index: 10;

.indicatorCircleContainer {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: $option-min-height-rem;
  line-height: $option-min-height-rem;
  overflow: hidden;

  * { cursor: pointer;}
}

.circle {
  -webkit-backface-visibility: hidden;
  background: transparent;
  border-radius: $sp-xl-rem / 2;
  height: $sp-xl-rem;
  width: $sp-xl-rem;
  transition: background $ease-in-out-transition, transform $ease-in-out-transition, z-index $ease-in-out-transition;

  position: absolute;
  top: 0;
  left: -50%;
  right: -50%;
  bottom: 0;
  z-index: $z-index-survey-body + $_circle-z-index;
  margin: auto;
}

.circle_isSelected {
  background: $color-blue;
  transform: scale(2, 2);
  -ms-transform: scale(2, 2);
  z-index: $z-index-survey-body + ($_circle-z-index * 3);
}

.label {
  position: absolute;
  top: 0;
  left: -50%;
  right: -50%;
  bottom: 0;
  margin: auto;
  height: $sp-xl-rem * 2;
  width: $sp-xl-rem * 2;
  line-height: $sp-xl-rem * 2;

  text-align: center;
  transition: color $ease-in-out-transition;
  user-select: none;
  vertical-align: middle;
  z-index: $z-index-survey-body + $_label-z-index;
}

.label_isSelected {
  color: $color-white;
  z-index: $z-index-survey-body + ($_label-z-index * 3);
}
