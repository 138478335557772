@import '../../../../css/variables.scss';

.wrapper {
  margin-top: 0;

  :global(.rtl) & {
    display: flex;
    justify-content: right;
  }
}

.title {
  font-weight: bold;
  margin-right: $sp-xs;
  white-space: nowrap;

  :global(.rtl) & {
    margin-left: $sp-xs;
  }
}