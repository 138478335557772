@import '../../../../../css/variables.scss';

.wrapper {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

@media #{$mobile-cookie-banner-with-wrapper} {
  .wrapper {
    flex-direction: column;
  }
}