@import '../../../css/variables';

:global(.anchors) {
  display: block;
  height: 0px;
  overflow: hidden;
}

:global(.anchor) {
  font-size: $fs-xss;
  vertical-align: bottom;
}

:global(.anchor_isJustified) {
  &:first-child, :global(.rtl) &:last-child {
    text-align: left;
  }

  &:last-child, :global(.rtl) &:first-child {
    text-align: right;
  }

  &:only-child, :global(.rtl) &:only-child {
    text-align: center;
  }
}

:global(.anchor_isNaOption) {
  text-align: center;
  height: 0px;
  overflow: hidden;
}

:global(.anchor_isForcedShown), :global(.anchor_isOnFirstRow) {
  height: initial;
  padding-bottom: $sp-l;
}

:global(.anchors_isFirstRow), :global(.anchors_isForcedDesktopShown) {
  display: table;

  :global(.anchor) {
    display: table-cell;
    padding-bottom: $sp-l;
  }
}

@media #{$mobile-tablet} {
  :global(.anchors) {
    display: table;
  }

  :global(.anchor) {
    padding-bottom: $sp-l;
  }
}
