@import '../../css/variables';

$_container-transition: 0.25s ease;
$_checkmark-transition: 0.25s ease-out;
$_checkmark-dimensions: $indicator-dimensions - 4px; // -4px for border

.indicatorCheckboxContainer {
  background: $color-white;
  border: 2px solid $color-gray-light;
  border-radius: $sp-xs;
  box-sizing: border-box;
  height: $indicator-dimensions;
  margin: auto;
  width: $indicator-dimensions;
  transition: background $_container-transition, border $_container-transition, transform $_container-transition;
}

.indicatorCheckboxContainer_isSelected {
  background: $color-blue;
  border: 2px solid $color-blue;
}

.checkmark {
  color: $color-white;
  font-size: $_checkmark-dimensions;
  height: $_checkmark-dimensions;
  width: $_checkmark-dimensions;
  opacity: 0;
  pointer-events: none;
  transition: opacity $_checkmark-transition, transform $_checkmark-transition;
  transform: translateZ(0) scale(2, 2);
  -webkit-backface-visibility: hidden;
}

.checkmark_isSelected {
  opacity: 1;
  transform: translateZ(0) scale(1, 1);
}
