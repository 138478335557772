@import '../../../css/variables';

.dropdown_withCookieBanner {
  z-index: $z-index-language-dropdown;
}

@media #{$cookie-banner-language-dropdown-centered} {
  .dropdown_withCookieBanner {
    max-width: 300px;
    margin: 0 auto;
  }
}

