// Source: https://github.com/geoffgraham/animate.scss

// duration     Specifies how many seconds or milliseconds an animation takes to complete one cycle. Default 0
//
// delay        Specifies when the animation will start. Default 0
//
// function     Describes how the animation will progress over one cycle of its duration. Default "ease"
//
// count        Specifies the number of times an animation is played. Default 1
//
// fill-mode    Specifies if the effects of an animation are before the animation starts and after it ends.
//
// visibility   Determines whether or not a transformed element is visible when it is not facing the screen.

$countDefault: 1 !default;
$durationDefault: 1s !default;
$delayDefault: 0s !default;
$functionDefault: ease !default;
$fillDefault: both;
$visibilityDefault: hidden !default;

@mixin count($count: 1) {
  -webkit-animation-iteration-count: $count;
     -moz-animation-iteration-count: $count;
      -ms-animation-iteration-count: $count;
       -o-animation-iteration-count: $count;
          animation-iteration-count: $count;
}

@mixin duration($duration: 1s) {
  -webkit-animation-duration: $duration;
     -moz-animation-duration: $duration;
      -ms-animation-duration: $duration;
       -o-animation-duration: $duration;
          animation-duration: $duration;
}

@mixin delay($delay: .2s) {
  -webkit-animation-delay: $delay;
     -moz-animation-delay: $delay;
      -ms-animation-delay: $delay;
       -o-animation-delay: $delay;
          animation-delay: $delay;
}

@mixin function($function: ease) {
  -webkit-animation-timing-function: $function;
     -moz-animation-timing-function: $function;
      -ms-animation-timing-function: $function;
       -o-animation-timing-function: $function;
          animation-timing-function: $function;
}

@mixin fill-mode($fill: both) {
  -webkit-animation-fill-mode: $fill;
     -moz-animation-fill-mode: $fill;
      -ms-animation-fill-mode: $fill;
       -o-animation-fill-mode: $fill;
          animation-fill-mode: $fill;
}

@mixin visibility($visibility: hidden) {
  -webkit-backface-visibility: $visibility;
     -moz-backface-visibility: $visibility;
      -ms-backface-visibility: $visibility;
       -o-backface-visibility: $visibility;
          backface-visibility: $visibility;
}

@mixin transform($property) {
  -webkit-transform: $property;
     -moz-transform: $property;
      -ms-transform: $property;
       -o-transform: $property;
          transform: $property;
}

@mixin transform-origin($transform-origin: center center) {
  -webkit-transform-origin: $transform-origin;
     -moz-transform-origin: $transform-origin;
      -ms-transform-origin: $transform-origin;
       -o-transform-origin: $transform-origin;
          transform-origin: $transform-origin;
}

@mixin transform-style($transform-style: flat) {
  -webkit-transform-style: $transform-style;
     -moz-transform-style: $transform-style;
       -o-transform-style: $transform-style;
          transform-style: $transform-style;
}

@mixin animation-name($animation-name) {
  -webkit-animation-name: $animation-name;
     -moz-animation-name: $animation-name;
      -ms-animation-name: $animation-name;
       -o-animation-name: $animation-name;
          animation-name: $animation-name;
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @-ms-keyframes #{$animation-name} {
    @content;
  }

  @-o-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

//fadeIn
@include keyframes(fadeIn) {
	  0% {opacity: 0;}
	100% {opacity: 1;}
}

//fadeOut
@include keyframes(fadeOut) {
  0% {opacity: 1;}
  100% {opacity: 0;}
}

@mixin fadeOut($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeOut);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
}

@mixin fadeIn($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
	@include animation-name(fadeIn);
	@include count($count);
	@include duration($duration);
	@include delay($delay);
	@include function($function);
	@include fill-mode($fill);
	@include visibility($visibility);
}

//fadeInUp
@include keyframes(fadeInUp) {
  0% {
    opacity: 0;
    @include transform(translate3d(0, 100%, 0));}
  100% {
    opacity: 1;
    @include transform(translate3d(0, 0, 0));
  }
}

@mixin fadeInUp($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(fadeInUp);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}

//slideInUp
@include keyframes(slideInUp) {
  0% {
    opacity: 0;
    @include transform(translateY(2000px));
  }
  100% {
    opacity: 1;
    @include transform(translateY(0));
  }
}

@mixin slideInUp($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(slideInUp);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}

//slideInRight
@include keyframes(slideInRight) {
  0% {
    opacity: 0;
    @include transform(translateX(40px));
  }
  100% {
    opacity: 1;
    @include transform(translateX(0));
  }
}

@mixin slideInRight($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(slideInRight);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}
