@import '../../../css/variables';

.container {
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2%;
  border: 1px solid $color-gray-light;
  min-height: 58px;
}

.preview {
  max-width: 100%;
  border: 2px solid $color-blue;
  background-color: white;
  box-shadow: 0px 13px 38px 3px rgba(0,0,0,0.37);
}

.icon_container {
  margin-right: 15px;
}

:global(.rtl) .icon_container {
  margin-right: 0;
  margin-left: 15px;
}

.number_container {
  box-sizing: border-box;
  background-color: $color-blue;
  color: white;
  border-radius: 50%;
  min-width: 32px;
  line-height: 32px;
  text-align: center;
  margin-right: 15px;

  &.is_dragging {
    background-color: transparent;
    color: transparent;
  }
}

.drop_target {
  box-sizing: border-box;
  border: 2px solid $color-blue;
}
:global(.rtl) .number_container {
  margin-right: 0;
  margin-left: 15px;
}

.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 24px;
}

.not_valid_target {
  pointer-events: none;
}