@import '../../css/variables';

$_indicator-radius: $indicator-dimensions / 2;

.indicatorRadioContainer {
  border: 2px solid $color-gray-light;
  border-radius: $_indicator-radius;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: $indicator-dimensions;
  transition: border 0.2s ease-in-out;
  width: $indicator-dimensions;
}

.indicatorRadioContainer_isSelected {
  border: ($_indicator-radius * 0.70) solid $color-blue;
}
