@import '../../../css/variables';

.item {
  box-sizing: border-box;
  border: 1px solid $color-gray-light;
  width: 100%;
  padding: 9px 16px;
  display: flex;
  position: relative;
  background: $color-white;
  justify-content: flex-start;
  align-items: center;
}

.text {
  padding-left: 2%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  line-height: 24px;
}