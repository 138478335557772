@import '../../../../css/variables.scss';

$_max-height: 400px;

.detailsWrapper {
  overflow-y: auto;
  max-height: $_max-height;
}

.details {
  background: $color-blue-light;
  border-radius: $sp-m;
  padding: $sp-m;
  margin-top: $sp-xxl;
  margin-bottom: $sp-m;
}

.row {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.title {
  font-weight: bold;
  font-size: $fs-xss;
  margin-right: $sp-m;
  margin-bottom: $sp-xl;
  flex-shrink: 0;
  max-width: 50%;
}

.info {
  font-size: $fs-xss;
  padding-right: $sp-xs;
}
