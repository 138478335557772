@import '../../../../../css/variables.scss';

.container {
  max-height: 220px;
  margin-top: $sp-xl;
  overflow: auto;
  padding-right: $sp-m;
}

.container_isShrinkedHeight {
  max-height: 120px;
}

.text {
  font-size: $fs-s;
  line-height: 22px;
  height: 100%;
  margin: 0;

  ul {
    margin-top: 20px
  }
}

.text_message {
  color: #1B2437;
  font-size: $fs-xss;
}

@media #{$mobile-only} {
  .container {
    height: 150px;
    margin-top: 14px;
  }
}

@media #{$mobile-cookie-banner-break-points} {
  .container {
    height: auto;
    overflow: auto;
    flex-shrink: 0;
  }
}