@import '../../css/variables';

.table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  position: relative;
  vertical-align: middle;
  width: 100%;
}

.table_hasFixedLayout {
  table-layout: fixed;
}
