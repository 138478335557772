@import './shared';

.containerError {
  color: $color-red;
  border: 1px solid $color-red;
}

.errorMessage {
  flex: 1;
  text-align: left;
}

.closeButton {
  display: inline-block;
  margin-left: auto;
  padding-left: $sp-xl;
  background: none;
  border: none;
  text-align: center;
  text-decoration: none;
}

@media #{$mobile-only} {
  .containerError {
    padding-left: $sp-m + $_mobileIconFontSize + $_mobileIconPaddingRight;
  }
}
