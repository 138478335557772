@import 'variables';
@import 'normalize';
@import 'animation';
@import 'rte';

html {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  height: 100%;
  background-color: $color-white;
}

body {
  color: $color-black-blue;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: $fs-m;
  line-height: $line-height;
  overflow-y: scroll;
  overflow-x: hidden;

  &:after {
    content: 'media-desktop';
    display: none;
  }
}

header {
  border-bottom: $border-width solid $color-gray-light;
}

a {
  color: $color-blue;
}

ul {
  margin: $sp-n;
  padding: $sp-n;
  list-style: none;
}

fieldset {
  border: none;
  margin: $sp-n;
  padding: $sp-n;
}

select {
  border: $border;
}

.rtl {
  direction: rtl;
}

.layoutContainer {
  margin: $sp-n auto;
  max-width: 768px;
  min-width: 320px;
  outline: none;
  padding: $sp-n $sp-xxl;
}

.layoutContainer_fadeInUp {
  @include fadeInUp($fill: none);
}

.layoutContainer_fadeIn {
  @include fadeIn($delay: .8s);
}

.surveyBody {
  position: relative;
  z-index: $z-index-survey-body;
}

.mainHeader {
  padding: $sp-xxxl $sp-n;
  min-height: 54px;
  display: table;
  width: 100%;
}

.mainHeaderLeft,
.mainHeaderRight {
  display: table-cell;
  vertical-align: middle;
}

.mainHeaderLeft {
  width: 70%;
  overflow: hidden;
}

.mainHeaderRight {
  width: 30%;
}

.invisibleTitle {
  margin: 0;
  height: 0;
  width: 0;
  font-size: 0px;
}

.logo {
  max-width: 100%;
}

.answers {
  position: relative;
  margin: $sp-n $sp-n $sp-xxxl;
}

.answer {
  background: $color-white;
}

.text {
  padding: $sp-xxxl $sp-n $sp-xxxl;
}

.invitationErrorFooterLink {
  text-decoration: underline;
  border: none;
  background: none;
  color: $color-blue;
  padding: $sp-n;
}

.yellowText {
  background-color: $color-yellow;
  padding: $sp-l;
}

.footer {
  font-size: $fs-xs;
  text-align: center;
  padding-bottom: $sp-xxxl;

  .medalliaLogo {
    height: auto;
    width: auto;
    font-size: $fs-ml;
    padding-bottom: 16px;
    color: $color-black-logo;
  }

  .marketingLinks {
    margin: 0 auto $sp-xs;
  }

  .legalLinks a {
    padding: $sp-n $sp-xxs;
    color: $color-black-blue;
  }
}

.skipNav {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  background-color: $color-denim;
  color: $color-white;
  display: none;
}

.skipNav {
  &:focus {
    position: static;
    width: auto;
    height: auto;
    color: white;
    float: left;
  }
}

.globalErrorBlock {
  padding-top: $sp-xxxl;
  padding-bottom: $sp-xxxl;
  color: $color-red;
}

.globalErrorBlockFocusNone:focus {
  outline: none;
}

//util
#acediv,
.aceswf {
  position: absolute;
  top: $sp-n;
  left: $sp-n;
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
}

.ui-helper-clearfix {
  @extend .clearfix;
}

.js-only,
.js .no-js-only {
  display: none
}

.js .js-only {
  display: block;
}

.mobileScreenOnly {
  display: none;
}

.notMobileScreenOnly {
  display: inline;
}

@media #{$internet-explorer-only} {
  .mainHeader {
    table-layout: fixed;
    display: block;
  }
}

@media #{$mobile-tablet} {
  body {
    font-size: 18px;

    &:after {
      content: 'media-mobile';
      display: none;
    }
  }

  // Remove tap highlight on iOS
  input,
  textarea,
  button,
  select,
  label,
  a {
    -webkit-tap-highlight-color: $color-mobile-tap;
  }

  input,
  textarea {
    border-radius: 0;
    -webkit-appearance: none;
  }

  .answers {
    margin: $sp-n $sp-n $sp-xxl;
  }

  .layoutContainer {
    margin: $sp-n auto;
    max-width: 600px;
    min-width: 245px;
    padding: $sp-n $sp-l;
  }

  .mainHeaderLeft,
  .mainHeaderRight {
    width: 50%;
  }

  .logo {
    height: auto;
  }

  .mobileScreenOnly {
    display: inline;
  }

  .notMobileScreenOnly {
    display: none;
  }

  .mainFooter .legalLinks {
    padding: $base-spacing-mobile 0;
  }
}

@media #{$mobile-only} {

  .mainHeaderLeft,
  .mainHeaderRight {
    width: 100%;
    display: table-row;
  }
}

.hasTouch {
  -webkit-overflow-scrolling: touch;
}

.assistiveText {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
}