@import '../../css/variables';

.uploadFormContainer {
  border-radius: 10px;
  border: 2px dashed $color-gray-light;
  padding: $sp-xxl $sp-xxxl $sp-xxl $sp-xxxl;
  text-align: center;
};

.fileListContainer + .actionText {
  margin-top: $sp-xxl;
}

.actionText {
  font-size: $fs-m;
  line-height: $fs-ml;
  margin: 0;
}

%actionButton {
  display: block;
  background: none;
  border: none;
  color: $color-blue;
  line-height: $fs-ml;
  margin: auto;
  padding-bottom: $sp-xxxl;
  text-align: center;
  text-decoration: none;
}

.disabledButton {
  color: $color-gray;
}

.browseFilesButton {
  @extend %actionButton;
}

.inlineBrowseFilesButton {
  @extend %actionButton;
  display: inline-block;
}

%infoText {
  color: $color-gray-meta;
  font-size: $fs-s;
  line-height: $fs-ml;
  margin-top: $sp-n;
  margin-bottom: $sp-n;
}

.metaInfoText {
  @extend %infoText;
}

.consentText {
  @extend %infoText;
  padding-top: $sp-xxl;
}

.uploadIcon {
  display: block;
  color: #CCCCCC;
  font-size: 36px;
  margin: auto;
  margin-bottom: $sp-m;
}

@media #{$mobile-only} {
  .uploadFormContainer {
    border: 2px dashed $color-gray-light;
    padding: $sp-xl $sp-m $sp-xl $sp-m;
  }

  .fileListContainer + .browseFilesButton {
    margin-top: $sp-xl;
  }

  .uploadIcon {
    margin-bottom: 10px;
  }

  .browseFilesButton {
    font-size: $fs-s;
    line-height: $fs-ml;
    padding-bottom: $sp-xl;
  }

  .inlineBrowseFilesButton {
    font-size: $fs-s;
    line-height: $fs-ml;
    padding-bottom: $sp-xl;
  }

  .metaInfoText {
    font-size: $fs-xss;
    line-height: $fs-m;
  }

  .consentText {
    font-size: $fs-xss;
    line-height: $fs-m;
  }
}
