@import '../../../../css/variables.scss';

.item {
  margin-top: $sp-xxxxxl;
  background-color: $color-white;
  border-radius: 18px;
  padding: $sp-m;
  font-size: $fs-xss;

  &:first-child {
    margin-top: 0;
  }
}

.id {
  font-weight: 700;
}

.row {
  display: flex;
  flex-direction: row;
  line-height: 1.5;

  p {
    margin-top: $sp-xs;
  }
}

.title {
  font-weight: bold;
  font-size: $fs-xss;
  margin-right: $sp-m;
  margin-bottom: $sp-xl;
}

.label {
  font-size: $fs-xss;
  margin-right: $sp-m;
  flex-shrink: 0;
  width: 30%;
  color: $color-gray-meta;
}

.info {
  font-size: $fs-xss;
  padding-right: $sp-xs;
}