@import '../../css/variables';

.validationBlock {
  display: block;
  font-size: $fs-s;
  margin-bottom: 8px;
}

.requiredBlock {
  opacity: 0.5;
}

/**
 * This class below increases the opacity on the "Required" message to
 * meet color contrast requirement with WCAG 2.1
 * See COCO-4702
 */
.opacityPointEight {
  opacity: 0.8;
}

.errorBlock {
  color: $color-red;
  opacity: 1;
}

.validationIcon {
  font-size: $fs-m;
  vertical-align: middle;

  :global(.rtl) & {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
}

.globalErrorBlock {
  padding: $sp-xl $sp-n;
}

.caption {
  margin-left: $sp-xs;
  vertical-align: middle;
  margin-right: $sp-n;

  :global(.rtl) & {
    margin-left: $sp-n;
    margin-right: $sp-xs;
  }
}
