@import '../../../../css/variables';

.container {
  padding: $sp-n $sp-xxs;
  text-align: center;
  width: 100%;
}

.link {
  background: transparent;
  color: $color-black-blue;
  text-decoration: underline;
  box-shadow: none;
  border: 0;
  padding: 0 2px;
}
