@import '../../css/variables';

$_height: 42px;
$_max-width: 400px;

.calendarContainer {
  background: white;
  border-spacing: 0;
  border: $border;
  display: table;
  margin: auto;
  max-width: $_max-width;
  table-layout: fixed;
  text-align: center;
  transform: translateZ(0);
  transition: transform $ease-in-out-transition;
  vertical-align: middle;
  width: 100%;

  tr > * {
    height: $_height;
  }
}
