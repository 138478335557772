@import '../../../css/variables';
@import '../../../css/animation';

.dropdownHidden {
  display: none;
}

.dropdownDisabled {
  opacity: 0.5;
}

.dropdownWrapper {
  margin-bottom: $sp-xxxl;
  width: 50%;
  @include fadeIn($duration: .8s, $fill: none, $visibility: initial);

  &.isSearchableDropdownEnabled {
    width: 100%;
  }
}

@media #{$mobile-tablet} {
  .dropdownWrapper {
    width: 100%;
  }
}
