@import '../../css/variables';
@import '../../css/animation';

.taText {
  padding: $sp-xxxl $sp-n $sp-m $sp-n;
  font-size: $fs-xl;
  line-height: 38px;
}

.infoIcon {
  margin-left: $sp-m;
  font-size: 22px;
}

.taWidgetIntro {
  font-size: $fs-s;
  padding-top: $sp-m;
  line-height: $line-height;
}

.taWidget {
  @include fadeIn();

  border: $border;
  width: 100%;
  height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
  box-shadow: $box-shadow-default;
}

.whatsThisTooltip {
  display: none;
  position: absolute;
  -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: $border-width solid #D3D3D3;
  padding: 8px;
  top: 25px;
  left: 25px;
  z-index: $z-index-survey-body + 1000;
  font-size: $fs-s;
}

.close {
	text-align: right;
}
