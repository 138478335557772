.errorMessage span {
  margin-left: 0;
}

.errorCode span {
  margin-left: 0;
  font-size: 12px;
}

.checkbox {
  margin: 1rem 0;
}
