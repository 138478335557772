@import '../../../../../css/variables.scss';

.title {
  font-size: $fs-m;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 0;

  :global(.rtl) & {
    text-align: right;
  }
}