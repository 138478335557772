@import '../../../css/variables';

$_height: 42px;
$_max-width: 400px;

.calendarTitleContainer {
  box-sizing: border-box;
  height: $_height;
  line-height: $_height;
  max-width: $_max-width;
  width: 100%;
}

.calendarTitleLabel {
  font-weight: normal;
}

.prevMonthLink, .nextMonthLink {
  color: $color-black-blue;
  display: block;
  font-size: $fs-ml;
  height: 100%;
  outline: none;
  text-decoration: none;
  width: 100%;

  :global(.rtl) & {
    transform: rotate(180deg);
  }
}

.prevMonthLink {
  left: 0;
}

.nextMonthLink {
  right: 0;
}
