@import '../../css/variables';

.progressBar {
  background-color: $color-gray-light;
  height: 6px;
  width: 100%;
  position: fixed;
  z-index: $z-index-top-layer;
}

.progressBarIndicator {
  background-color: $color-green;
  height: 100%;
}
