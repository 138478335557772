@font-face {
  font-family: 'medallia-star-scales';
  font-style: normal;
  font-weight: 400;
  src: url('./star-scales.eot'); /* IE9 Compat Modes */
  src: url('./star-scales.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./star-scales.woff2') format('woff2'), /* Super Modern Browsers */
       url('./star-scales.woff') format('woff'), /* Modern Browsers */
       url('./star-scales.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./star-scales.svg#star-scales') format('svg'); /* Legacy iOS */
}

:global(.fontStarScales) {
  font-family: 'medallia-star-scales', sans-serif;
}
