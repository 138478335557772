@import '../../css/variables';

.wrapper {
  width: 100%;
  overflow: hidden;
  border: 1px solid #dde1e5;

  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }

  input:checked {
    ~ .content {
      max-height: 100vh;
      padding: 0.2rem 1rem;
    }
  }

  .label {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0.2rem 1rem;
    background: white;
    cursor: pointer;
    font-weight: bold;
    font-size: $fs-xss;

    &:hover {
      background: darken(white, 10%);
    }

    i {
      color: $color-blue-dark;
    }

    .icon-expand {
      transform: rotate(0deg);
    }
    
    .icon-close {
      transform: rotate(90deg);
    }
  }

  .content {
    max-height: 0;
    margin-left: 1.5rem;
    padding: 0 1rem;
    background: white;
    transition: all 0.35s;
    font-size: $fs-s;
  }
}
