@import '../../../css/variables';

.selectionBarContainer {
  border-collapse: separate;
}

.selectionUnit {
  box-sizing: border-box;
  border-top: $border;
  border-left: $border;
  border-bottom: $border;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: $color-mobile-tap;

  &:last-child {
    border-right: $border;
  }

  :global(.rtl) &:last-child {
    border-right: none;
  }

  :global(.rtl) &:first-child {
    border-right: $border;
  }
}
