@import "../../../../../css/variables.scss";

.wrapper {
  height: 28px;
}

@media #{$mobile-cookie-banner-with-wrapper} {
  .wrapper {
    height: 26px;

    &.showBackButton {
      height: 42px;
    }
  }
}

.backButton {
  background: none;
  border: none;
  display: flex;
  color: #246dd7;
  flex-direction: row;
  font-size: $fs-s;
  padding: 0 1px 0 0;
  text-decoration: none;
  line-height: $sp-xxl;
  align-items: center;

  span {
    margin-left: $sp-xs;
  }

  :global(.rtl) & {
    flex-direction: row-reverse;
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &.showBackButton {
    justify-content: space-between;
  }

  button {
    border: none;
    background: none;
  }
}