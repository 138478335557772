@import '../../css/variables';
@import '../../css/animation';

.modalBackdrop {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: $z-index-modal;
  background-color: rgba(33, 49, 68, 0.8);
  display: block;
  overflow: hidden;
  @include fadeIn($duration: .4s);
}

.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: block;
  background: $color-white;
  @include slideInUp($duration: .7s);
}

.modalHeader {
  height: 54px;
  border-bottom: $border-width solid $color-gray;
  text-align: center;
  line-height: 54px;
  padding: 0 $sp-xl;
  box-sizing: border-box;
  position: relative;
  z-index: $z-index-modal + 1;
}

.modalLabel {

}

.modalBody {
  position: absolute;
  top: 53px;
  bottom: 0;
  right: 0;
  left: 0;
  padding: $sp-n $sp-xl $sp-xl;
  height: calc(100% - 53px);
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  z-index: $z-index-modal + 1;

  :global(.rtl) & {
    text-align: right;
  }

  > ul {
    border-left: 0;
    border-right: 0;
  }
}

.closeIconContainer {
  position: absolute;
  right: 0;
  height: 100%;
  width: 60px;
  color: $color-black-blue;
  text-align: right;
  text-decoration: none;
  z-index: $z-index-top-layer;
  outline: none;

  :global(.rtl) & {
    left: 0;
    right: initial;
    text-align: left;
  }
}

.closeIcon {
  margin-right: $sp-xl;

  :global(.rtl) & {
    margin-left: $sp-xl;
  }
}

@media #{$mobile-tablet}  {
  .modalOpen {
    overflow-y: hidden;
    overflow-x: hidden;
    position: fixed;
  }
}
