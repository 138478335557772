@import "../../css/variables";

.progressStepsContainer {
  display: table;
  table-layout: fixed;
  border-spacing: $sp-m;
  width: 100%;
}

.progressStep {
  display: table-cell;
  height: 8px;
  width: 18%;
  background-color: $color-gray;
  border-radius: $sp-xxs;
  transition: $background-color-transition;
}

.progressStep_isActivated {
  background-color: $color-gray-black;
}

@media #{$mobile-tablet} {
  .progressStepsContainer {
    border-spacing: $sp-xs;
  }

  .progressStep {
    margin: $sp-n $sp-xxs;
  }
}
