@import '../../../css/variables';

.draggable_list {
  list-style-type: none;
  padding: 0;
  
  &:focus {
    outline: 2px solid $color-blue;
  }

  > *:focus {
    outline: 2px solid $color-blue;
  }
}