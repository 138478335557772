@import '../../css/variables';

.dropdownWrapper {
  margin-bottom: $sp-xxxl;
  width: 50%;
}

@media #{$mobile-tablet} {
  .dropdownWrapper {
    width: 100%;
  }
}

// Matrix styles
.subComponentDropdown {
  min-width: 90%;
  width: 100%;
  font-size: $fs-xss;
  margin-bottom: 0;

  :global(.dropdown_dropdownTitle) {
    padding: 18px 20px;
  }
  :global(.dropdown_dropdownIcon) {
    right: 15px;
  }
}

.subComponentFieldset {
  min-inline-size: 100%;
}

.subComponentMainContainer {
  width: 100%;
}
