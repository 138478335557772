@import '../../css/variables';

$_max-width: 550px;
$_max-width-mobile: 288px - (2 * $sp-xl);

.tooltipContainer {
  position: relative;
  display: inline-block;
  outline: none;
  vertical-align: middle;
}

.tooltipIcon {
  padding: 0 $sp-m;
  cursor: pointer;
}

.tooltipContentContainer {
  background: white;
  border-radius: $sp-xs;
  border: $border;
  box-shadow: $box-shadow-default;
  outline: none;
  padding: $sp-l $sp-xl;
  z-index: $z-index-top-layer + 100;
  max-width: $_max-width;
}

.tooltipContentContainer_isInvisible {
  visibility: hidden;
}

.tooltipContent {
  max-width: $_max-width;
  visibility: visible;
}

.tooltipContent_isInvisible {
  visibility: hidden;
}

@media #{$mobile-tablet} {
  .tooltipContent, .tooltipContentContainer {
    max-width: $_max-width-mobile;
  }
}
