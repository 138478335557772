@import "../../css/variables";

.popupContainer {
  padding: $sp-xxxl 0;
  outline: none;
}

.toolip {
  margin-left: -$sp-m;
}

@media #{$mobile-only} {
  .popupContainer {
    padding: $sp-xxl 0;
  }
}
