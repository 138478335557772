@import "../../../../../css/variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 64px;
  max-height: 200px;
  justify-content: space-around;
  margin-bottom: $sp-l;
  margin-top: 6px;

  a {
    background: none;
    border: none;
    color: #246dd7;
    display: flex;
    flex-direction: row;
    font-size: $fs-s;
    text-decoration: none;
    padding: 2px 0 2px 0;

    span {
      margin-right: $sp-xs;
    }
  }

  a:hover:focus {
    outline: auto !important;
  }
}

.wrapper.isRow {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.wrapper.noMargin {
  margin: 0;
}

@media #{$mobile-only} {
  .wrapper {
    margin-bottom: 12px;
    margin-top: 10px;
  }
}

@media #{$mobile-cookie-banner-break-points} {
  .wrapper {
    margin-top: 14px;
    margin-bottom: 16px;
  }
}