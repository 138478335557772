@import '../../css/variables.scss';

@media #{$mobile-cookie-banner-with-wrapper} {
  .withCookieBanner {
    z-index: $z-index-language-dropdown;
  }
}

@media #{$cookie-banner-language-dropdown-centered} {
  .withCookieBanner {
    position: fixed;
    left: 0;
    width: 100%;
    text-align: center;
    // When cookie banner is displayed this class controls the language selector
    margin-top: 0 !important;
    top: 24px;
    z-index: $z-index-language-dropdown;
  }
}
