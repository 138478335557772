@import '../../../../css/variables.scss';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.requiredLabel {
  font-size: $fs-xs;
  font-weight: 700;
}
