@import './shared';

.contentContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  min-width: 0;
  text-align: left;
}

.container_fileIsDeleted {
  display: none;
}

.fileName {
  overflow: hidden;
  padding-right: $sp-s;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.isHiddenForAria {
  position: absolute;
  opacity: 0;
}

$_progressBarHeight: 10px;

.progressContainer {
  display: inline-block;
  background-color: $color-gray;
  border-radius: $_progressBarHeight/ 2;
  height: $_progressBarHeight;
  opacity: 1;
  overflow: hidden;
  transition: opacity $ease-in-out-transition;
  width: 100%;
}

.progressContainer_isDone {
  opacity: 0;
}

.progressBar {
  background-color: $color-blue;
  height: 100%;
  transform-origin: left;
  transition: transform $ease-in-out-transition;
  width: 100%;
}

@media #{$mobile-only} {
  .fileName {
    padding-right: $sp-s;
    line-height: $_mobileDocumentIconHeight;
  }

  .progressContainer {
    height: $sp-s;
  }
}
