@import '../../../css/variables';

.input {
  position: relative;
  box-sizing: border-box;
  margin-bottom: $sp-l;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-property: padding-top, padding-bottom, height;
  z-index: $z-index-survey-body-input-layer + 10;

  &:hover, &:focus {
    box-shadow: none;
    border: $border;
  }
}

.textfieldValidationError {
  margin-bottom: 8px;
  max-height: 60px;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  transition-property: max-height;
}

.textfieldValidationError_isHidden {
  max-height: 0;
  margin: 0;
}

@media #{$mobile-tablet} {
  .input {
    &:hover, &:focus {
      box-shadow: none;
    }
  }
}

