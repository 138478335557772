@import '../../css/variables';

.timeoutWarningContainer {
  max-width: 600px;
  height: 240px;
  color: $color-black-blue;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.timeoutWarningBodyMessage {
 padding: $sp-xxxl $sp-n;
}

@media #{$mobile-tablet} {
  .timeoutWarningContainer {
    width: auto;
    height: 260px;
    margin: auto $sp-l;
  }
}
