@import "../../../../../css/variables.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

@media  #{$mobile-cookie-banner-break-points} {
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
}
