@import '../../../css/variables';

.rowOptionContainer {
  margin-bottom: $sp-xxl;
}

.hiddenAriaLegend {
  display: block;
  position: fixed;
  opacity: 0;
  width: 0;
  user-select: none;
}

.anchorsContainer {
  vertical-align: bottom;
}

.topCaptionContainer {
  margin-bottom: $sp-xxl;
}

.topCaption {
  font-size: $fs-m;
  margin: 0;
  width: 100%;
}

.leftCaptionContainer {
  width: 30%;
  padding-right: 10px;

  :global(.rtl) & {
    padding-right: 0;
    padding-left: 10px;
  }
}

.leftCaptionContainer_isForcedHidden {
  display: none;
}

.leftCaption {
  width: 100%;
}

.mobileNaOption {
  width: $option-min-height;
}

.mobileNaOptionCaption {
  font-size: $fs-xs;
}

.mobileNaOptionContainer {
  position: relative;
  display: none;
  border: $border;
  border-top: none;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  transition: transform $ease-in-out-transition, box-shadow $ease-in-out-transition;
  vertical-align: middle;

  -webkit-appearance:none;
  -moz-appearance:none;

  :global(.hasNoTouch) &:hover {
    box-shadow: $box-shadow-focus;
    border: $border-width solid transparent;
    border-top: none;
    transform: translateZ(0);
    z-index: $z-index-survey-body + 1;
  }
}

.naAnchorContainer, .naSelectionContainer {
  min-width: 64px;
  padding-left: $sp-m;
  vertical-align: bottom;

  :global(.rtl) & {
    padding-left: 0;
    padding-right: $sp-m;
  }
}

.naSelectionContainer {
  cursor: pointer;
  vertical-align: middle;
}

.naOption {
  border: $border;
  border-collapse: separate;
  box-shadow: $box-shadow-default;
  height: $option-min-height-rem-2;
  transition: box-shadow $ease-in-out-transition;
  z-index: 7;

  :global(.hasNoTouch) &:hover {
    border: $border-width solid transparent;
    box-shadow: $box-shadow-focus;
    transform: translateZ(0);
  }
}

.selectionUnitsContainer {
}

.selectionUnits {
  box-shadow: $box-shadow-default;
}

@media #{$mobile-tablet} {
  .topCaptionContainer {
    display: block;
    margin-bottom: $sp-xl;
  }

  .naAnchorContainer,
  .naSelectionContainer,
  .leftCaptionContainer {
    display: none;
  }

  .naOption:hover {
    box-shadow: none;
  }

  .mobileNaOptionContainer {
    display: table;

    &:hover, :global(.hasTouch) &:hover {
      box-shadow: none;
      border: $border;
      border-top: none;
    }
  }

  .rowGroupContainer {
    margin-bottom: $sp-xxl;
  }
}
