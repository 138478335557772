@import '../../../css/variables';

.draggable_item_content {
  cursor: grab;
};

.draggable_item {
  opacity: 1;
  overflow: visible;
  transition: border-bottom-color 0.2s;
  border-top: none;
  &:focus{
    border:none;
  }
}

.dragging {
  background-color: rgba(216, 216, 216, 0.6);

  & > * {
    opacity: 0;
  }
}

.selected {
  box-sizing: border-box;
  transform: scale(1.03) translate(0px, -5px);
  outline: none;
  z-index: 1;
  background-color: white;
  border: 2px solid $color-blue;
}

.drop_target {
  box-sizing: border-box;
  border: 2px solid $color-blue;
}

.listItemDragging {
  &:focus {
    outline: none !important;
  }
}