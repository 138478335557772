@import "../../../../../css/variables.scss";

// TODO: To Fix observed issue on V1 and V3 banners:: "The containers overlap when backdrop is enabled for IOS 14 and below"
// To resolve set the z-index:$z-index-modal; but this causes another issue where the accept and close button remain unclickable.
//----------------------------------------------------------------------
// V1
//----------------------------------------------------------------------

.v1.wrapper {
  z-index: $z-index-modal;

  &.isEnhancedCookieBannerEnabled {
    border: none;
    padding: 0;
  }
}

.isEnhancedCookieBannerEnabled.v1.wrapper::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

html:has(.isEnhancedCookieBannerEnabled.v1.wrapper[open]) {
  overflow: hidden;
}

.v1.container {
  width: 300px;
  height: auto;
  max-height: 518px;
}

//----------------------------------------------------------------------
// V3
//----------------------------------------------------------------------

.v3.wrapper {
  border: none;
  padding: 0;
}

.v3.wrapper::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

html:has(.v3.wrapper[open]) {
  overflow: hidden;
}

.v3.container {
  width: 565px;
}

@media #{$mobile-cookie-banner-with-wrapper} {
  .v3.wrapper {
    padding: revert;
  }
}

//----------------------------------------------------------------------
// VARIANTS
//----------------------------------------------------------------------

.container.primary {
  background-color: #ffffff;
}

.container.secondary {
  background-color: #f8f9fa;
}

//----------------------------------------------------------------------
// SHARED
//----------------------------------------------------------------------

.container {
  box-shadow: 0px 6px 8px 1px rgba(0, 0, 0, 0.2);
  border-top-left-radius: $sp-xl;
  border: $border;
  border-radius: 16px 0 0 0;
  bottom: 0;
  padding: $sp-xl;
  position: fixed;
  right: 0;
  z-index: $z-index-cookie-banner;
  max-height: 75vh;
}

@media #{$mobile-cookie-banner-with-wrapper} {
  .wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
  }

  .container {
    bottom: 0px;
    right: 0;
    left: 0;
    margin: auto;
  }

  .v1.container {
    width: 250px;
    border-radius: 0px;
    padding: $sp-l;
    max-height: 518px;
  }

  .v3.container {
    border-radius: 18px 18px 0px 0px;
    width: calc(100vw - 42px) !important;
    max-height: 100vh;
  }
}

@media #{$cookie-banner-small-desktop-height-break-points} {
  .v3.container {
    max-height: 100vh;
  }
}

@media #{$mobile-only-landscape} {
  .container {
    height: calc(100vh - #{$base-landscape-spacing-header} - 32px - 2px); // 32 paddings and 2 borders
  }
}

.v1.breakingVersion {
  height: auto !important;
}