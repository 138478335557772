@import '../../../css/variables';

.mobileCellQuestion {
  width: 100%;
  display: block;
}

.cell {
  display: table-cell;
  min-width: 178px;
  vertical-align: top;
  padding: 0 $sp-s $sp-xl;
}

.internalAnchorCell {
  padding: 0 8px;
  vertical-align: bottom;
}

.internalAnchorCellNA {
  display: flex !important;
  justify-content: end;
  padding-right: 20px;
}

.mobileCellQuestion {
  width: 100%;
  display: block;
}

@media #{$mobile-tablet} {
  .cell {
    padding: 0;
  }
}
