@import '../../../css/variables';

.group {
  border: $border;
  box-shadow: $box-shadow-default;
}

.groupCaption {
  padding-bottom: 16px;
}

.optionContainer {
  border-bottom: $border;

  &:last-child {
    border-bottom: none;
  }
}

.isBazaarVoiceTerms {
  border: none;
  box-shadow: none;
}

.tableRow {
  display: table-row;
}