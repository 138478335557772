@import '../../css/variables';

@mixin arrowGradient($direction: left) {
  background: linear-gradient(to $direction, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0));
}

.mainContainer {
  position: relative;
}

.contentWrapper {
  width: auto;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &:before, &:after {
    position: absolute;
    content: " ";
    width: 10px;
    height: 100%;
    top: 0;
    z-index: 1;
  }

  &:before {
    @include arrowGradient(right);
    left: 0;
    border-top-right-radius: 10px 5%;
    border-bottom-right-radius: 10px 5%;
  }

  &:after {
    @include arrowGradient(left);
    right: 0;
    border-top-left-radius: 10px 5%;
    border-bottom-left-radius: 10px 5%;
  }

}
.hideLeftIndicator {
  &:before, .leftIcon {
    display: none;
  }
}
.hideRightIndicator {
  &:after, .rightIcon {
    display: none;
  }
}

.icon {
  position: absolute;
  border-radius: 50%;
  background-color: $color-blue;
  top: calc(50% - 20px);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4000;
  color: #FFF;
  cursor: pointer;
  box-shadow: 0px 5px 10px 0px rgba(0, 2, 8, .35);

  i { padding-top: 3px }
}

.overflowYVisibleWorkaround {
  // This is a workaround to prevent the dropdown items to be hidden
  margin-bottom: -250px;
  padding-bottom: 250px;
}

.leftIcon {
  left: -15px;
}

.leftIconContent {
  transform: rotate(90deg);
}

.rightIcon {
  right: -15px;
}

.rightIconContent {
  transform: rotate(-90deg);
}
