@import '../../../css/variables';

.mobileColumnCaption {
  width: 100%;
  padding: 10px 0;
  font-size: $fs-m;
}

.mobileSubComponentCell {
  display: block;
}

.dropdown {}

.textfield {
  :global(.textfieldQuestion .textField_textField) {
    font-size: $fs-xss;
    padding: 18px 20px;
  }
  :global(.textfieldQuestion .question_questionTitle) {
    padding: 0;
  }
}

.textarea {
  :global(.textareaQuestion) {
    width: 100%;
    height: 100%;
  }
  :global(.textareaQuestion .textarea_textarea) {
    font-size: $fs-xss;
    padding: 11px 12px;
    min-height: 55px;
    height: auto;
  }
  :global(.textareaQuestion .question_questionTitle) {
    padding: 0;
  }
  :global(.textareaQuestion .layout_answers) {
    margin: 0;
  }
  :global(.textareaQuestion .textarea_textareaCount) {
    bottom: -16px;
    padding-right: 0;
  }
}

.grid {
  :global(.ratingGridQuestion .layout_answers),
  :global(.ratingGridQuestion .rowOption_rowOptionContainer) {
    margin: 0;
  }
  :global(.ratingGridQuestion .cell_cell.layout_answer) {
    width: 50px;
  }
  :global(.ratingGridQuestion .cell_cell.rowOption_naSelectionContainer) {
    min-width: 80px;
  }
}

@media #{$mobile-tablet} {
  .grid {
    :global(.ratingGridQuestion .cell_cell.layout_answer) {
      width: 26px;
    }
  }
  .longform, .choose {
    height: auto;
  }
  .mobileColumnCaption {
    padding: 20px 0 20px 0px;

  }
}
